body {
  background-color: #1D2438;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  min-height: 100vh;
  color: white;
  text-align: left;
}

.App h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 28px;
}

.App-logo {
  pointer-events: none;
  width: 924px;
  max-width: 100%;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 60px;
}

.App-link {
  color: #61dafb;
}

.btn-primary {
  background-color: #1756fa;
  border: none;
}
.btn-primary:disabled {
  color: #677e9a;
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.pink {
  background-color: #fb0c62;
}
.pink:hover {
  background-color: #c90657;
}

.purple {
  background-color: #3c09cd;
}
.purple:hover {
  background-color: #2700ae;
}

.light-blue {
  background-color: #00c3ee;
}

.red-link {
  color: #eb5454;
  font-weight: 700;
}
.red-link:hover {
  color: #c93930;
}

/* Input styles */
input {
  border: 2px solid #f3f3f3 !important;
}

input::placeholder {
  color: #677e9a !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(251 229 251) !important;
  border-color: #f1ebf1 !important;
}

/* Text styles */
a {
  color: #1756fa;
}

a:hover {
  text-decoration: none;
  color: #fb0c62;
}

.text-weight-400 {
  font-weight: 400;
}
.text-weight-500 {
  font-weight: 500;
}
.text-weight-600 {
  font-weight: 600;
}

.text-lato {
  font-family: "Lato";
}

.text-sm {
  font-size: 14px;
}
.text-md {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 20px;
}
.text-xxl {
  font-size: 22px;
}
.text-xxxl {
  font-size: 24px;
}

.text-white {
  color: white;
}
.text-cobble {
  color: #677e9a;
}
.text-gray {
  color: rgba(255, 255, 255, 0.5);
}
.text-success {
  color: #16bb58;
}
.text-danger {
  color: #f90909;
}
.text-pink {
  color: #fb0c62;
}
.text-link {
  color: #1756fa;
}
.text-black {
  color: #000000;
}
.text-purple {
  color: #b454ff;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

.text-blue-underline {
  border-bottom: 2px solid #2be3cd;
}

.pointer {
  cursor: pointer;
}

hr {
  border-top: 1px solid #f3f3f3;
}

.modal {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  top: 50%;
  left: 50%;
  width: 450px; /* Full width */
  height: fit-content;
  margin-top: -125px; /* Negative half of height. */
  margin-left: -225px; /* Negative half of width. */

  color: #0d0426;
  width: 450;
  height: 250;
  background-color: #e7e7e7;

  border-radius: 15px;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.modal-container {
  padding: 15px;
  flex-direction: column;
}

.modal-container-h1 {
  font-size: 64pt;
}

.modal-button {
  margin-left: auto;
  margin-right: auto;
  flex-grow: initial;
}

.modal-subtitle {
  font-size: 14px;
  color: #474747;
}

.modal-passsword {
  font-size: 20px;
  color: #000000;
}
/* ADD TO CALENDAR OVERRIDES */
.chq-atc--dropdown a {
  color: black !important;
  padding: 12px 18px !important;
}

.chq-atc--dropdown a:hover {
  color: #fb0c62 !important;
  background-color: transparent !important;
}

.chq-atc--dropdown {
  box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125) !important;
  top: 50px;
}

.chq-atc--button {
  border-radius: 0.25rem !important;
  color: #212529 !important;
  padding: 0.5rem 3rem !important;
  font-weight: 500;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chq-atc--button:hover {
  color: #212529 !important;
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
}

.chq-atc--button svg {
  display: none;
}
